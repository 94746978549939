.header {
  position: sticky;
  top: 0;
  background-color: $color-white;
  padding: $font-size 0;
  z-index: 3;
  transition: $trans;
  box-shadow: $box-shadow-sm;
  @media screen and (max-width: 767px) {
    padding: calc($font-size/2) 0;
  }
  &__container {
    display: flex;
    align-items: center;
    gap: $space-1;
    @media screen and (max-width: 767px) {
      justify-content: flex-start;
      gap: $font-size-smaller;
    }
  }

  &__burger {
  }

  &__logo {
    img {
      width: 90px;
      height: auto;
      @media screen and (max-width: 991px) {
        width: 70px;
      }
    }
  }

  &__nav {
    margin-left: auto;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &__cta {
    @media screen and (max-width: 991px) {
      font-size: $font-size-smaller;
      margin-left: auto;
      padding-left: 0.75rem;
    }
    .boton {
      @media screen and (max-width: 991px) {
        font-size: $font-size-smaller;
        padding: 4px 10px;
        background-color: $color-primary;
        color: $color-white;
      }
    }
  }
}

.nav-ecom {
  display: flex;
  gap: $font-size;
  @media screen and (max-width: 767px) {
    gap: $font-size-smaller;
  }
  &__item {
    display: flex;
  }
}

.cart-totals {
  &__items {
  }

  &__price {
  }
}

.circle-container {
  // background-color: $color-grey-1;
  aspect-ratio: 1 / 1;
  // width: 40px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    font-size: $header-3;
    color: $color-grey-1;
  }
}
