.nav-primary {
  display: flex;
  align-items: center;

  &__items {
    list-style: none;
    display: flex;
    margin-bottom: 0px;

    li.menu-item {
      a {
        color: $color-dark;
        font-weight: $font-weight-medium;
        text-decoration: none;
        padding: 0 $font-size-smaller;
      }
    }
    li.nav-feat {
      a {
        color: $color-primary;
        font-weight: $font-weight-bold;
      }
    }
  }
}

.nav-off {
  margin-bottom: $space-1;

  &__items {
    list-style: none;
    margin: 0;
    padding: 0;

    li.menu-item {
      // margin-bottom: 5px;
      a {
        font-size: $header-2;
        color: $color-white;
        font-weight: $font-weight-bold;
        text-decoration: none;
      }
    }
  }
}

.offcanvas {
  &-title {
    font-size: $font-size-smaller;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    letter-spacing: 1px;
  }
  &-logo {
    img {
      width: 100px;
      height: auto;
    }
  }
}
