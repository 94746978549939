body.woocommerce-cart .woocommerce {
  display: grid;
  grid-template-columns: 3.3fr 2fr;
  grid-column-gap: 2rem;
  grid-row-gap: 0;
  @media (max-width: 991px) {
    display: block;
  }
  .woocommerce-notices-wrapper {
    grid-column: 1 / 3;
  }
}

.cart_totals,
.shop_table {
  width: 100%;
}

/* Tabla del carrito de la compra CELDAS INDIVIDUALES */
.woocommerce-cart-form {
  margin-bottom: 2rem;

  .shop_table {
    width: 100%;
    box-shadow: none !important;
  }

  .product-remove {
    width: 40px;
    font-size: 1.2rem;
    text-align: center;
    &::before {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .product-remove a {
    font-size: 1.5rem;
    color: rgba(129, 13, 13, 0.774) !important;
  }

  // .product-thumbnail {
  //   display: flex;
  //   align-items: center;
  //   height: 100%;
  // }

  .product-thumbnail a {
    display: flex;
  }

  .product-thumbnail img {
    width: 50px;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  .product-name {
    width: 50%;
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1rem !important;
    .variation {
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 5px 10px;
      font-size: 12px;
      margin-top: 1rem;
      margin-bottom: 0px;
      font-family: $typo-secondary;
      @media screen and (max-width: 767px) {
        grid-template-columns: 3fr 1fr;
      }
      dt {
        margin-right: 5px;
        font-weight: normal;
        margin-bottom: 3px;
        // flex: 0 0 30% !important;
      }
      dd {
        margin-left: 0;
        margin-right: 5px;
        margin-bottom: 3px;
        // flex: 0 0 80% !important;

        p {
          margin: 0;
          display: inline-flex;
        }
      }
    }
  }

  .product-price {
    text-align: right;
  }

  .product-quantity {
    text-align: right;
    .qty {
      padding: 9px 2px;
    }
  }

  .product-subtotal {
    font-weight: bold;
    text-align: right;
    .tax_label {
      font-size: 11px !important;
      font-weight: normal;
      display: block;
    }
  }

  thead {
    tr th {
      height: 3rem;
      line-height: 3rem;
      font-size: 0.8rem !important;
      text-transform: uppercase;
    }
  }
}

/* Tabla del carrito de la compra -------------------------- */
.woocommerce-cart-form table.shop_table {
  // @include card-cart;
  thead {
    @media (max-width: 767px) {
      display: none;
    }
  }

  tbody {
    tr {
      @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
      }
    }
    td {
      @media (max-width: 767px) {
        display: block;
        width: 100%;
        text-align: right;
        font-size: 0.9rem;
      }
    }
    td.product-thumbnail {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .cart_item {
    @media (max-width: 767px) {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      padding: 2px;
      margin-bottom: 10px;
    }
  }
  .cart_item td {
    border-bottom: 2px solid $color-background;
    background-color: rgba(0, 0, 0, 0.03);
  }

  td {
    padding: 20px 9px;
    vertical-align: middle;
    line-height: 1.5em;
    @media screen and (max-width: 575px) {
      padding: 5px 9px;
    }
  }

  td a {
    color: $color-primary-dark;
  }

  th {
    font-weight: 700;
    padding: 7px 9px;
    line-height: 1.5em;
  }

  .actions {
    padding: 1rem 0 0 0;
    &::before {
      @media (max-width: 575px) {
        display: none;
      }
    }
  }
}

.coupon {
  width: 100%;
  margin-bottom: 0.5rem;
  label {
    display: none;
  }
  @media (max-width: 767px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
  }
  input {
    @media (max-width: 767px) {
      margin-right: 10px;
      width: 50%;
    }
  }
  button {
    padding: 12px 15px;
  }
}

/* Atributos en tabla para responsive --------------- */
.woocommerce table.shop_table_responsive tr td::before,
.woocommerce-page table.shop_table_responsive tr td::before {
  @media (max-width: 767px) {
    content: attr(data-title) ": ";
    font-weight: 400;
    float: left;
  }
}

.cart_totals table.shop_table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%;
  border-collapse: separate;
  border-radius: 5px;
}

.woocommerce table.shop_table .screen-reader-text {
  display: none;
}

.update_cart {
  float: right;
}

.cart-collaterals {
  width: 100%;
  display: flex;
  justify-content: right;
}

/* CARRITO TOTALES Y TRANSPORTE ------------------- */

.cart_totals {
  display: block;
  // @include card-cart;
  @media (max-width: 767px) {
    width: 100%;
    font-size: 0.9rem !important;
  }
  h2 {
    font-size: 1.5rem !important;
  }
  .shop_table {
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;
    border-radius: 3px;
  }

  tbody tr td,
  tbody tr th {
    padding: 12px 12px !important;
  }

  tbody tr td {
    border: 1px solid rgba(0, 0, 0, 0.05);
    &::before {
      display: none;
    }
  }

  tbody tr th {
    font-size: $font-size-smaller;
    text-align: right;
    width: 25%;
    @media (max-width: 767px) {
      width: 25%;
    }
  }

  tbody tr th:nth-child(1) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  h2 {
    margin-top: 0;
  }

  .checkout-button {
    display: block;
    font-size: 1.3rem;
    text-align: center;
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
  }
}

.woocommerce-shipping-methods {
  padding-left: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 5px !important;

  li {
    margin-bottom: 1rem;
    input.shipping_method {
      margin-right: 5px;
    }
    label {
      font-weight: bold;
      // color: green;
      .woocommerce-Price-amount {
        color: $color-grey-3;
      }
    }

    label span {
      font-weight: normal;
    }

    .betrs_option_desc {
      font-size: $font-size-small;
      line-height: 1.2rem;
      a {
        color: $color-whatsapp-dark;
      }
    }
  }
}

.woocommerce #content table.cart td.actions,
.woocommerce table.cart td.actions,
.woocommerce-page #content table.cart td.actions,
.woocommerce-page table.cart td.actions {
  text-align: right;
}

.woocommerce #content table.cart td.actions .coupon,
.woocommerce table.cart td.actions .coupon,
.woocommerce-page #content table.cart td.actions .coupon,
.woocommerce-page table.cart td.actions .coupon {
  float: left;
}

// @media (max-width: 767px) {
//   .woocommerce table.shop_table_responsive tr td::before,
//   .woocommerce-page table.shop_table_responsive tr td::before {

//     content: attr(data-title) ": ";
//     font-weight: 700;
//     float: left;
//   }
// }

// Widget carrito

.off-carrito {
  &__title {
    margin-bottom: 1rem;
    font-size: $header-6;
  }
  .hook-cart {
    font-size: $font-size-small;
  }
}

// Mini cart_totals

.widget_shopping_cart {
  &_content {
    ul.product_list_widget {
      padding-left: 0;

      li.mini_cart_item {
        list-style: none;
        background-color: $color-grey-6;
        padding: 0.5rem;
        border-radius: $border-radius;
        margin-bottom: 0.5rem;
        position: relative;
        a.remove {
          color: $color-grey-3;
          position: absolute;
          right: 1rem;
          top: 1rem;
        }
        a {
          .attachment-woocommerce_thumbnail,
          .wp-post-image {
            width: 50px;
            height: auto !important;
            border: 1px solid $color-grey-5;
            margin-right: 1rem;
            border-radius: $border-radius-min;
          }
        }
        .quantity {
        }
      }
    }
    .woocommerce-mini-cart {
      &__buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .button {
          border-radius: 50px;
          width: 100%;
          text-align: center !important;
          margin-bottom: $font-size;
          font-size: $font-size !important;
          letter-spacing: 0;
          text-align: center;
          justify-content: center;
        }
        .wc-forward {
          font-weight: 400;
          background-color: $color-grey-6;
          color: $color-grey-3;
          font-size: $font-size-small;
          font-weight: $font-weight-medium;
        }
        .checkout.wc-forward {
          background-color: $color-secondary;
          padding: 0.85rem 1.875rem !important;
          font-weight: $font-weight-bold;
          color: $color-white;
        }
      }
      &__total {
        border-top: 1px dashed $color-grey-4;
        padding-top: $space-1;
        margin-top: $space-1;
        font-size: $font-size-big;
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        .amount {
          color: $color-grey-1;
          font-weight: $font-weight-bold;
        }
      }
      &__empty-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          display: flex;
          padding: 1rem 0;
        }
        img {
          width: 100px;
          height: auto;
        }
      }
    }
  }
}

.m-cart {
  display: flex;
  padding-right: 2rem;
  &__pic {
  }
  &__content {
    font-size: $font-size-small;
    color: $color-grey-1;
    .quantity {
      display: block;
      font-size: $font-size-smaller;
      color: $color-grey-4;
      bdi {
        color: $color-secondary;
      }
    }
  }
}

.wd-empty-mini-cart::before {
  display: block;
  margin-bottom: 20px;
  color: rgba(135, 135, 135, 0.15);
  font-size: 86px;
  line-height: 1;
  content: "\f147";
  font-family: "woodmart-font";
}
