@mixin input-buttons {
  padding: 0.625rem 1.875rem;
  border: 2px;
  border-radius: 5px;
  letter-spacing: 1px;
  font-weight: 500;
  cursor: pointer;
  transition: $trans;
  display: inline-flex;
  align-items: center;
  @media (max-width: 575px) {
    padding: 0.75rem 1.2rem;
  }
  @media (max-width: 390px) {
    padding: 0.7rem 1rem;
    font-size: 0.8rem;
  }
  .bi {
    margin-left: 5px;
  }
}

@mixin input-buttons-hover {
  // transform: translateY(-2px);
  box-shadow: $box-shadow-hover;
  transition: $trans;
}
