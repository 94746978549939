.footer {
  padding: $space-3 0 $space-1;
  background-color: $color-primary-dark;
  @media screen and (max-width: 991px) {
    padding: $space-2 0 $space-1;
  }
  &__container {
  }
  &__titulo {
    color: $color-white;
    font-size: $header-3;
    font-weight: bold;
    margin-bottom: $font-size;
  }
  &__navs {
    margin-bottom: $space-3;
    @media screen and (max-width: 991px) {
      margin-bottom: $space-1;
    }
  }
  &__nav {
  }
  &__contact {
    margin-bottom: $space-1;
    padding-bottom: $space-3;
    border-bottom: solid 1px $color-white;
    border-style: dashed;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    @media screen and (max-width: 991px) {
      padding-bottom: $space-2;
      margin-bottom: $space-2;
    }
  }
  &__copy {
    display: flex;
    justify-content: space-between;
    color: $color-white;
    font-size: $font-size-small;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
    > * {
      color: $color-white;
    }
    a {
      text-decoration: none;
      color: $color-white;
    }
  }
}
.nav-footer {
  color: $color-white;
  &__items {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
    }
  }
  li.menu-item {
    margin-right: $font-size;
    @media screen and (max-width: 767px) {
      margin-bottom: calc($font-size/2);
    }
    a {
      color: $color-trans;
      font-weight: $font-weight-medium;
      text-decoration: none;
      margin: 0;
    }
  }
}

.contactos {
  display: flex;
  gap: $space-1;

  &__item {
    a {
      display: block;
      text-decoration: none;
      color: $color-trans;
      font-weight: $font-weight-normal;
    }
  }

  &__output {
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
  }

  &__titulo {
    color: $color-white;
    font-weight: bold;
    margin-bottom: $font-size-smaller;
  }
}

.next-gen {
  padding-bottom: $space-1;

  @media screen and (max-width: 575px) {
    padding-bottom: $space-1;
  }
  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $space-1 $space-1;
    flex-wrap: wrap;
    // flex-direction: column;
    padding-top: $space-1;
    border-top: 1px dashed $color-white;
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      gap: calc($font-size / 2);
    }
  }

  &__logos {
    display: flex;
    gap: $space-1;
  }
  &__img {
    img {
      width: 180px;
      height: auto;
      @media screen and (max-width: 575px) {
        width: 100px;
      }
    }
  }

  &__description {
    color: $color-dark;
    font-size: $font-size-smaller;
    @media screen and (max-width: 575px) {
      text-align: center;
    }
  }
}
